.we-section .container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: column;
  -ms-flex-direction: column;
  align-items: center;
  -ms-flex-align: center;
  padding: 4.375rem 0;
  margin: 4.375rem 0 3.125rem 0;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray; }
  .we-section .container .item {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: column;
    -ms-flex-direction: column;
    align-items: center;
    -ms-flex-align: center;
    -webkit-flex: 1 0 30%;
    /* Safari 6.1+ */
    -ms-flex: 1 0 30%;
    /* IE 10 */
    flex: 1 0 30%;
    -ms-flex: 1 0 auto;
    margin: 0 0.625rem;
    width: 30%;
    text-align: center; }
    .we-section .container .item > h3 {
      font-size: 1rem; }
    .we-section .container .item > h1 {
      font-size: 4.1875rem;
      font-weight: 500;
      color: #5ab7ff;
      margin: 0.625rem 0; }
      .we-section .container .item > h1 > span {
        color: #5ab7ff; }
    .we-section .container .item > p {
      width: 100%;
      font-size: 0.875rem; }
  .we-section .container .row {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: row;
    -ms-flex-direction: row;
    justify-content: space-between;
    -ms-flex-pack: justify;
    width: 100%; }
  .we-section .container > :first-child.row {
    margin-bottom: 1.25rem; }

.we-section .member_of {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: row;
  -ms-flex-direction: row;
  justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  -ms-flex-align: center;
  margin: 1.25rem 0; }
  .we-section .member_of .text-wrapper {
    margin-right: 1.25rem; }
    .we-section .member_of .text-wrapper > p {
      margin-bottom: 5px;
      font-size: 0.875rem; }
  .we-section .member_of .image-wrapper {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: row;
    -ms-flex-direction: row;
    justify-content: center;
    -ms-flex-pack: center; }
    .we-section .member_of .image-wrapper > span {
      margin: 0.9375rem; }

@media (max-width: 1023px) {
  .we-section .bg {
    height: auto; }
  .we-section .container .item {
    width: 100%;
    -webkit-flex: 1 1 100%;
    /* Safari 6.1+ */
    -ms-flex: 1 1 100%;
    /* IE 10 */
    flex: 1 1 100%;
    margin-bottom: 3.125rem; }
    .we-section .container .item > h1 {
      margin: 0; }
  .we-section .container .row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .we-section .member_of {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: column;
    -ms-flex-direction: column; }
    .we-section .member_of .text-wrapper {
      text-align: center; }
    .we-section .member_of .image-wrapper {
      display: -webkit-box;
      /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box;
      /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox;
      /* TWEENER - IE 10 */
      display: -webkit-flex;
      /* NEW - Chrome */
      display: flex;
      /* NEW, Spec - Opera 12.1, Firefox 20+ */
      flex-direction: row;
      -ms-flex-direction: row;
      justify-content: center;
      -ms-flex-pack: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .we-section .member_of .image-wrapper > img {
        width: 4.375rem;
        height: 4.375rem;
        margin: 0.625rem; } }
