body {
  margin: 0;
  padding: 0; }

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 0 auto;
  background: #eee; }
  .page .subpage {
    background: #fff;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: column;
    -ms-flex-direction: column;
    justify-content: space-between;
    -ms-flex-pack: justify; }
    .page .subpage > :nth-child(2n) {
      margin: 4cm 0; }
    .page .subpage > :first-child {
      margin: 0; }
    .page .subpage .contents {
      height: 108.5mm;
      border: 1px solid black;
      width: 100%;
      padding: 1cm;
      position: relative; }
      .page .subpage .contents > img {
        width: 90%;
        opacity: 0.2;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1; }
      .page .subpage .contents > .origin-section {
        height: 2cm;
        margin-bottom: 0.5cm; }
        .page .subpage .contents > .origin-section > .id {
          font-size: 30px;
          margin-bottom: 20px; }
          .page .subpage .contents > .origin-section > .id > span {
            margin-left: 10px;
            font-size: 17px;
            font-weight: 500; }
        .page .subpage .contents > .origin-section > .flag-origin-region {
          display: -webkit-box;
          /* OLD - iOS 6-, Safari 3.1-6 */
          display: -moz-box;
          /* OLD - Firefox 19- (buggy but mostly works) */
          display: -ms-flexbox;
          /* TWEENER - IE 10 */
          display: -webkit-flex;
          /* NEW - Chrome */
          display: flex;
          /* NEW, Spec - Opera 12.1, Firefox 20+ */
          flex-direction: row;
          -ms-flex-direction: row; }
          .page .subpage .contents > .origin-section > .flag-origin-region > .origin-region {
            margin-left: 10px;
            font-size: 20px;
            font-weight: 700; }
      .page .subpage .contents > .info-box {
        display: -webkit-box;
        /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;
        /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;
        /* TWEENER - IE 10 */
        display: -webkit-flex;
        /* NEW - Chrome */
        display: flex;
        /* NEW, Spec - Opera 12.1, Firefox 20+ */
        flex-direction: column;
        -ms-flex-direction: column;
        justify-content: space-between;
        -ms-flex-pack: justify;
        height: 63.5mm; }
        .page .subpage .contents > .info-box > section > .info-wrapper {
          width: 100%; }
          .page .subpage .contents > .info-box > section > .info-wrapper > .info {
            display: -webkit-box;
            /* OLD - iOS 6-, Safari 3.1-6 */
            display: -moz-box;
            /* OLD - Firefox 19- (buggy but mostly works) */
            display: -ms-flexbox;
            /* TWEENER - IE 10 */
            display: -webkit-flex;
            /* NEW - Chrome */
            display: flex;
            /* NEW, Spec - Opera 12.1, Firefox 20+ */
            flex-direction: row;
            -ms-flex-direction: row;
            justify-content: space-between;
            -ms-flex-pack: justify;
            margin-bottom: 10px; }
            .page .subpage .contents > .info-box > section > .info-wrapper > .info > p {
              font-size: 20px; }
            .page .subpage .contents > .info-box > section > .info-wrapper > .info > h5 {
              text-align: right;
              font-size: 20px; }
      .page .subpage .contents > .left {
        width: 100%;
        height: 100%;
        padding-right: 20px;
        display: -webkit-box;
        /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;
        /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;
        /* TWEENER - IE 10 */
        display: -webkit-flex;
        /* NEW - Chrome */
        display: flex;
        /* NEW, Spec - Opera 12.1, Firefox 20+ */
        flex-direction: column;
        -ms-flex-direction: column;
        justify-content: space-between;
        -ms-flex-pack: justify;
        -webkit-flex: 0 0 45%;
        /* Safari 6.1+ */
        -ms-flex: 0 0 45%;
        /* IE 10 */
        flex: 0 0 45%; }
        .page .subpage .contents > .left > .img-wrapper {
          position: relative;
          width: 90%;
          height: 200px;
          margin: 0 auto; }
          .page .subpage .contents > .left > .img-wrapper > .img {
            width: 100%;
            height: 100%; }
          .page .subpage .contents > .left > .img-wrapper > .flag {
            padding: 5px;
            position: absolute;
            top: 0;
            left: 0; }
        .page .subpage .contents > .left > .id {
          width: 100%;
          text-align: center; }
      .page .subpage .contents > .right {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;
        /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;
        /* TWEENER - IE 10 */
        display: -webkit-flex;
        /* NEW - Chrome */
        display: flex;
        /* NEW, Spec - Opera 12.1, Firefox 20+ */
        flex-direction: column;
        -ms-flex-direction: column;
        justify-content: space-between;
        -ms-flex-pack: justify; }
        .page .subpage .contents > .right > .info-box {
          display: -webkit-box;
          /* OLD - iOS 6-, Safari 3.1-6 */
          display: -moz-box;
          /* OLD - Firefox 19- (buggy but mostly works) */
          display: -ms-flexbox;
          /* TWEENER - IE 10 */
          display: -webkit-flex;
          /* NEW - Chrome */
          display: flex;
          /* NEW, Spec - Opera 12.1, Firefox 20+ */
          flex-direction: column;
          -ms-flex-direction: column;
          justify-content: space-between;
          -ms-flex-pack: justify;
          -webkit-flex: 1 0 auto;
          /* Safari 6.1+ */
          -ms-flex: 1 0 auto;
          /* IE 10 */
          flex: 1 0 auto; }
          .page .subpage .contents > .right > .info-box > .info-wrapper {
            width: 100%; }
            .page .subpage .contents > .right > .info-box > .info-wrapper > .info {
              display: -webkit-box;
              /* OLD - iOS 6-, Safari 3.1-6 */
              display: -moz-box;
              /* OLD - Firefox 19- (buggy but mostly works) */
              display: -ms-flexbox;
              /* TWEENER - IE 10 */
              display: -webkit-flex;
              /* NEW - Chrome */
              display: flex;
              /* NEW, Spec - Opera 12.1, Firefox 20+ */
              flex-direction: row;
              -ms-flex-direction: row;
              justify-content: space-between;
              -ms-flex-pack: justify;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              margin-bottom: 10px; }
              .page .subpage .contents > .right > .info-box > .info-wrapper > .info > p {
                font-size: 17px; }
              .page .subpage .contents > .right > .info-box > .info-wrapper > .info > h5 {
                font-size: 17px; }
          .page .subpage .contents > .right > .info-box > .info-wrapper2 {
            width: 100%; }
            .page .subpage .contents > .right > .info-box > .info-wrapper2 > .info {
              display: -webkit-box;
              /* OLD - iOS 6-, Safari 3.1-6 */
              display: -moz-box;
              /* OLD - Firefox 19- (buggy but mostly works) */
              display: -ms-flexbox;
              /* TWEENER - IE 10 */
              display: -webkit-flex;
              /* NEW - Chrome */
              display: flex;
              /* NEW, Spec - Opera 12.1, Firefox 20+ */
              flex-direction: row;
              -ms-flex-direction: row;
              justify-content: space-between;
              -ms-flex-pack: justify;
              margin-bottom: 10px; }
              .page .subpage .contents > .right > .info-box > .info-wrapper2 > .info > p {
                -webkit-flex: 1 0 120px;
                /* Safari 6.1+ */
                -ms-flex: 1 0 120px;
                /* IE 10 */
                flex: 1 0 120px;
                font-size: 17px; }
              .page .subpage .contents > .right > .info-box > .info-wrapper2 > .info > h5 {
                text-align: right;
                font-size: 17px; }
          .page .subpage .contents > .right > .info-box > .impt {
            width: 100%;
            border-left: 5px solid #0072ce;
            padding-left: 10px; }
            .page .subpage .contents > .right > .info-box > .impt > .info {
              display: -webkit-box;
              /* OLD - iOS 6-, Safari 3.1-6 */
              display: -moz-box;
              /* OLD - Firefox 19- (buggy but mostly works) */
              display: -ms-flexbox;
              /* TWEENER - IE 10 */
              display: -webkit-flex;
              /* NEW - Chrome */
              display: flex;
              /* NEW, Spec - Opera 12.1, Firefox 20+ */
              flex-direction: row;
              -ms-flex-direction: row;
              justify-content: space-between;
              -ms-flex-pack: justify; }
              .page .subpage .contents > .right > .info-box > .impt > .info > h5 {
                font-size: 18px; }

@page {
  size: A4;
  margin: 0; }

@media print {
  html, body {
    width: 210mm;
    height: 297mm; }
  .page {
    margin: 0;
    border: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always; } }
