body {
  margin: 0;
  padding: 0;
  /* background: #f3f3f3; */
  background: white;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
button {
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}
h1, h2, h3, h4, h5 {
  margin: 0;
  color: #000000;
}

p, a, li {
  margin: 0;
  color: #3d3d3d;
}

li {
  font-size: 0.875rem;
}

p, a {
  font-size: 0.875rem;
  font-weight: 500;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
