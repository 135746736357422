.hero-section {
  height: fit-content; }
  .hero-section > :first-child {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -2; }
    .hero-section > :first-child > img {
      height: 100vh;
      object-fit: cover; }
  .hero-section .dimmer {
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
    position: fixed;
    z-index: -1; }
  .hero-section .container {
    width: 90%;
    max-width: 75rem;
    height: 100%;
    margin: 0 auto;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    -ms-flex-pack: center; }
    .hero-section .container .box * {
      color: #FAFAFA; }
    .hero-section .container .box > img {
      position: absolute;
      top: 2.5rem;
      width: 15rem; }
    .hero-section .container .box > .we_are {
      margin-top: 7.5rem; }
    .hero-section .container .box > h1 {
      font-size: 2.1875rem;
      margin-bottom: 1.25rem;
      line-height: 2.6875rem; }
    .hero-section .container .box > p {
      font-size: 1.125rem;
      line-height: 1.4375rem;
      margin-bottom: 4.0625rem; }
    .hero-section .container .box > button {
      width: 100%;
      padding: 1.25rem 2.5rem;
      background: #008EFB;
      color: #FAFAFA;
      font-weight: bold;
      border: none; }
      .hero-section .container .box > button:hover {
        cursor: pointer; }

@media (max-width: 649px) {
  .hero-section .container .box {
    width: 100%; }
    .hero-section .container .box > button {
      padding: 1.25rem 0; } }
