.container {
  background: #fafafa;
  padding: 2rem 5rem; }
  @media (max-width: 779px) {
    .container {
      padding: 5rem 0.625rem; } }

.footer {
  width: 90%;
  max-width: 50rem;
  margin: 0 auto; }
  .footer > img {
    width: 15rem;
    margin-bottom: 2.5rem; }
  .footer > h5 {
    font-size: 1.125rem;
    margin-bottom: 0.625rem; }
  .footer > div {
    display: flex;
    align-items: center; }
    .footer > div > svg {
      margin-right: 0.4375rem;
      flex-shrink: 0; }
    .footer > div > p {
      font-size: 0.875rem;
      font-weight: 400; }
    .footer > div > a {
      font-size: 0.875rem;
      font-weight: 400; }
  .footer > div + div {
    margin-top: 1rem; }
  .footer > p {
    font-size: 0.875rem;
    margin-top: 2.5rem; }
