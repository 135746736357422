.floating {
  position: fixed;
  bottom: 1.875rem;
  right: 1.875rem;
  background: #008EFB;
  color: white;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 100px;
  text-align: center;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: row;
  -ms-flex-direction: row;
  align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  -ms-flex-pack: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  animation: fadein 0.5s;
  -moz-animation: fadein 0.5s;
  /* Firefox */
  -webkit-animation: fadein 0.5s;
  /* Safari and Chrome */
  -o-animation: fadein 0.5s;
  /* Opera */ }
  .floating > svg {
    color: white;
    font-size: 1.25rem; }
  .floating:hover {
    background: #0071c8; }
  .floating:focus {
    outline: none; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
