.bg {
  background: #f4f4f4; }
  .bg .partner {
    padding: 3.75rem 1.25rem;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: column;
    -ms-flex-direction: column;
    align-items: center;
    -ms-flex-align: center; }
    .bg .partner > h3 {
      color: #3D3D3D;
      font-size: 1.375rem;
      margin-bottom: 2.1875rem; }
      @media (max-width: 1023px) {
        .bg .partner > h3 {
          font-size: 1rem;
          margin-bottom: 0.625rem; } }
    .bg .partner > span {
      max-width: 800px;
      width: 100%; }
      @media (max-width: 1023px) {
        .bg .partner > span {
          width: 100%;
          height: auto; } }
