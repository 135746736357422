body {
  margin: 0;
  padding: 0;
  /* background: #f3f3f3; */
  background: white;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
button {
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}
h1, h2, h3, h4, h5 {
  margin: 0;
  color: #000000;
}

p, a, li {
  margin: 0;
  color: #3d3d3d;
}

li {
  font-size: 0.875rem;
}

p, a {
  font-size: 0.875rem;
  font-weight: 500;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.HeroSection_hero-section__p63dc {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.HeroSection_hero-section__p63dc > :first-child {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -2; }

.HeroSection_hero-section__p63dc > :first-child > img {
  height: 100vh;
  object-fit: cover; }

.HeroSection_hero-section__p63dc .HeroSection_dimmer__1Nxw- {
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
  position: fixed;
  z-index: -1; }

.HeroSection_hero-section__p63dc .HeroSection_container__MKF2H {
  width: 90%;
  max-width: 75rem;
  height: 100%;
  margin: 0 auto;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -ms-flex-pack: center; }

.HeroSection_hero-section__p63dc .HeroSection_container__MKF2H .HeroSection_box__15qrz * {
  color: #FAFAFA; }

.HeroSection_hero-section__p63dc .HeroSection_container__MKF2H .HeroSection_box__15qrz > img {
  position: absolute;
  top: 2.5rem;
  width: 15rem; }

.HeroSection_hero-section__p63dc .HeroSection_container__MKF2H .HeroSection_box__15qrz > .HeroSection_we_are__24d9h {
  margin-top: 7.5rem; }

.HeroSection_hero-section__p63dc .HeroSection_container__MKF2H .HeroSection_box__15qrz > h1 {
  font-size: 2.1875rem;
  margin-bottom: 1.25rem;
  line-height: 2.6875rem; }

.HeroSection_hero-section__p63dc .HeroSection_container__MKF2H .HeroSection_box__15qrz > p {
  font-size: 1.125rem;
  line-height: 1.4375rem;
  margin-bottom: 4.0625rem; }

.HeroSection_hero-section__p63dc .HeroSection_container__MKF2H .HeroSection_box__15qrz > button {
  width: 100%;
  padding: 1.25rem 2.5rem;
  background: #008EFB;
  color: #FAFAFA;
  font-weight: bold;
  border: none; }

.HeroSection_hero-section__p63dc .HeroSection_container__MKF2H .HeroSection_box__15qrz > button:hover {
  cursor: pointer; }

@media (max-width: 649px) {
  .HeroSection_hero-section__p63dc .HeroSection_container__MKF2H .HeroSection_box__15qrz {
    width: 100%; }
  .HeroSection_hero-section__p63dc .HeroSection_container__MKF2H .HeroSection_box__15qrz > button {
    padding: 1.25rem 0; } }

.WeAreSection_we-section__1AGMf .WeAreSection_container__1wsUt {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -ms-flex-align: center;
  padding: 4.375rem 0;
  margin: 4.375rem 0 3.125rem 0;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray; }

.WeAreSection_we-section__1AGMf .WeAreSection_container__1wsUt .WeAreSection_item__3mb_W {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -ms-flex-align: center;
  -webkit-flex: 1 0 30%;
  /* Safari 6.1+ */
  /* IE 10 */
  flex: 1 0 30%;
  -ms-flex: 1 0 auto;
  margin: 0 0.625rem;
  width: 30%;
  text-align: center; }

.WeAreSection_we-section__1AGMf .WeAreSection_container__1wsUt .WeAreSection_item__3mb_W > h3 {
  font-size: 1rem; }

.WeAreSection_we-section__1AGMf .WeAreSection_container__1wsUt .WeAreSection_item__3mb_W > h1 {
  font-size: 4.1875rem;
  font-weight: 500;
  color: #5ab7ff;
  margin: 0.625rem 0; }

.WeAreSection_we-section__1AGMf .WeAreSection_container__1wsUt .WeAreSection_item__3mb_W > h1 > span {
  color: #5ab7ff; }

.WeAreSection_we-section__1AGMf .WeAreSection_container__1wsUt .WeAreSection_item__3mb_W > p {
  width: 100%;
  font-size: 0.875rem; }

.WeAreSection_we-section__1AGMf .WeAreSection_container__1wsUt .WeAreSection_row__2RCZt {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify;
  width: 100%; }

.WeAreSection_we-section__1AGMf .WeAreSection_container__1wsUt > :first-child.WeAreSection_row__2RCZt {
  margin-bottom: 1.25rem; }

.WeAreSection_we-section__1AGMf .WeAreSection_member_of__3D9Pj {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
          align-items: center;
  -ms-flex-align: center;
  margin: 1.25rem 0; }

.WeAreSection_we-section__1AGMf .WeAreSection_member_of__3D9Pj .WeAreSection_text-wrapper__8Al4a {
  margin-right: 1.25rem; }

.WeAreSection_we-section__1AGMf .WeAreSection_member_of__3D9Pj .WeAreSection_text-wrapper__8Al4a > p {
  margin-bottom: 5px;
  font-size: 0.875rem; }

.WeAreSection_we-section__1AGMf .WeAreSection_member_of__3D9Pj .WeAreSection_image-wrapper__10wmY {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -ms-flex-pack: center; }

.WeAreSection_we-section__1AGMf .WeAreSection_member_of__3D9Pj .WeAreSection_image-wrapper__10wmY > span {
  margin: 0.9375rem; }

@media (max-width: 1023px) {
  .WeAreSection_we-section__1AGMf .WeAreSection_bg__QgggF {
    height: auto; }
  .WeAreSection_we-section__1AGMf .WeAreSection_container__1wsUt .WeAreSection_item__3mb_W {
    width: 100%;
    -webkit-flex: 1 1 100%;
    /* Safari 6.1+ */
    /* IE 10 */
    flex: 1 1 100%;
    margin-bottom: 3.125rem; }
  .WeAreSection_we-section__1AGMf .WeAreSection_container__1wsUt .WeAreSection_item__3mb_W > h1 {
    margin: 0; }
  .WeAreSection_we-section__1AGMf .WeAreSection_container__1wsUt .WeAreSection_row__2RCZt {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
  .WeAreSection_we-section__1AGMf .WeAreSection_member_of__3D9Pj {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    -webkit-flex-direction: column;
            flex-direction: column; }
  .WeAreSection_we-section__1AGMf .WeAreSection_member_of__3D9Pj .WeAreSection_text-wrapper__8Al4a {
    text-align: center; }
  .WeAreSection_we-section__1AGMf .WeAreSection_member_of__3D9Pj .WeAreSection_image-wrapper__10wmY {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -ms-flex-pack: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
  .WeAreSection_we-section__1AGMf .WeAreSection_member_of__3D9Pj .WeAreSection_image-wrapper__10wmY > img {
    width: 4.375rem;
    height: 4.375rem;
    margin: 0.625rem; } }

.SelectSection_bg__2AW_r {
  background: white; }

.SelectSection_bg__2AW_r .SelectSection_select-section__22DVp {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  max-width: 56.25rem;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify;
  padding: 5rem 0; }

.SelectSection_bg__2AW_r .SelectSection_select-section__22DVp > .SelectSection_box__yGQK_ {
  -webkit-flex: 0 0 48%;
  /* Safari 6.1+ */
  /* IE 10 */
  flex: 0 0 48%;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify;
  height: 20rem; }

.SelectSection_bg__2AW_r .SelectSection_select-section__22DVp > .SelectSection_box__yGQK_ .SelectSection_text-wrapper__3XWOA > h4 {
  font-size: 1.25rem;
  margin-bottom: 0.9375rem;
  color: black; }

.SelectSection_bg__2AW_r .SelectSection_select-section__22DVp > .SelectSection_box__yGQK_ .SelectSection_text-wrapper__3XWOA > p {
  font-size: 1rem;
  margin-bottom: 0.9375rem; }

.SelectSection_bg__2AW_r .SelectSection_select-section__22DVp > .SelectSection_box__yGQK_ > button {
  font-size: 1.25rem;
  width: 100%;
  height: 3.125rem; }

.SelectSection_bg__2AW_r .SelectSection_select-section__22DVp > .SelectSection_box__yGQK_ > button.SelectSection_buyer__rM95o {
  background: #008EFB;
  color: white; }

.SelectSection_bg__2AW_r .SelectSection_select-section__22DVp > .SelectSection_box__yGQK_ > button.SelectSection_buyer__rM95o:hover {
  background: #0071c8; }

.SelectSection_bg__2AW_r .SelectSection_select-section__22DVp > .SelectSection_box__yGQK_ > button.SelectSection_seller__2SMnW {
  background: white;
  border: 1px solid black; }

.SelectSection_bg__2AW_r .SelectSection_select-section__22DVp > .SelectSection_box__yGQK_ > button.SelectSection_seller__2SMnW:hover {
  border-color: #757575;
  color: #757575; }

@media (max-width: 767px) {
  .SelectSection_bg__2AW_r .SelectSection_select-section__22DVp {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    -webkit-flex-direction: column;
            flex-direction: column; }
  .SelectSection_bg__2AW_r .SelectSection_select-section__22DVp > .SelectSection_box__yGQK_ {
    height: unset;
    margin-bottom: 5rem; }
  .SelectSection_bg__2AW_r .SelectSection_select-section__22DVp > .SelectSection_box__yGQK_ .SelectSection_text-wrapper__3XWOA {
    margin-bottom: 1.25rem; }
  .SelectSection_bg__2AW_r .SelectSection_select-section__22DVp > .SelectSection_box__yGQK_ .SelectSection_text-wrapper__3XWOA > p {
    margin-bottom: 0.625rem;
    height: unset; } }

.PartnerSection_bg__58Xz2 {
  background: #f4f4f4; }

.PartnerSection_bg__58Xz2 .PartnerSection_partner__1pMdJ {
  padding: 3.75rem 1.25rem;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -ms-flex-align: center; }

.PartnerSection_bg__58Xz2 .PartnerSection_partner__1pMdJ > h3 {
  color: #3D3D3D;
  font-size: 1.375rem;
  margin-bottom: 2.1875rem; }

@media (max-width: 1023px) {
  .PartnerSection_bg__58Xz2 .PartnerSection_partner__1pMdJ > h3 {
    font-size: 1rem;
    margin-bottom: 0.625rem; } }

.PartnerSection_bg__58Xz2 .PartnerSection_partner__1pMdJ > span {
  max-width: 800px;
  width: 100%; }

@media (max-width: 1023px) {
  .PartnerSection_bg__58Xz2 .PartnerSection_partner__1pMdJ > span {
    width: 100%;
    height: auto; } }

.MemberSection_bg__20hbQ {
  background: white;
  padding: 5rem; }

@media (max-width: 779px) {
  .MemberSection_bg__20hbQ {
    padding: 5rem 0.625rem; } }

.MemberSection_container__3I4D2 {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  max-width: 50rem; }

.MemberSection_container__3I4D2 .MemberSection_item__2vYzv {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -ms-flex-align: center;
  margin-bottom: 2.5rem; }

.MemberSection_container__3I4D2 .MemberSection_item__2vYzv > span > img {
  width: 10.625rem;
  margin-right: 2.8125rem;
  border-radius: 6.25rem; }

.MemberSection_container__3I4D2 .MemberSection_item__2vYzv .MemberSection_text-box__3nB0T {
  flex: 1 1 -moz-fit-content;
  -ms-flex: 1; }

.MemberSection_container__3I4D2 .MemberSection_item__2vYzv .MemberSection_text-box__3nB0T > h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.625rem; }

.MemberSection_container__3I4D2 .MemberSection_item__2vYzv .MemberSection_text-box__3nB0T > p {
  font-size: 0.875rem;
  line-height: 1.6; }

@media (max-width: 779px) {
  .MemberSection_container__3I4D2 .MemberSection_item__2vYzv {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    -webkit-flex-direction: column;
            flex-direction: column; }
  .MemberSection_container__3I4D2 .MemberSection_item__2vYzv > span > img {
    margin-right: 0; }
  .MemberSection_container__3I4D2 .MemberSection_item__2vYzv .MemberSection_text-box__3nB0T > h4 {
    text-align: center;
    margin-top: 1.25rem; } }

.FloatingButton_floating__1f_t1 {
  position: fixed;
  bottom: 1.875rem;
  right: 1.875rem;
  background: #008EFB;
  color: white;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 100px;
  text-align: center;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -ms-flex-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  -ms-flex-pack: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  animation: FloatingButton_fadein__16q2C 0.5s;
  -moz-animation: FloatingButton_fadein__16q2C 0.5s;
  /* Firefox */
  -webkit-animation: FloatingButton_fadein__16q2C 0.5s;
  /* Safari and Chrome */
  -o-animation: FloatingButton_fadein__16q2C 0.5s;
  /* Opera */ }

.FloatingButton_floating__1f_t1 > svg {
  color: white;
  font-size: 1.25rem; }

.FloatingButton_floating__1f_t1:hover {
  background: #0071c8; }

.FloatingButton_floating__1f_t1:focus {
  outline: none; }

@keyframes FloatingButton_fadein__16q2C {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes FloatingButton_fadein__16q2C {
  /* Safari and Chrome */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.Footer_container__3a8Sk {
  background: #fafafa;
  padding: 2rem 5rem; }

@media (max-width: 779px) {
  .Footer_container__3a8Sk {
    padding: 5rem 0.625rem; } }

.Footer_footer__3lHA_ {
  width: 90%;
  max-width: 50rem;
  margin: 0 auto; }

.Footer_footer__3lHA_ > img {
  width: 15rem;
  margin-bottom: 2.5rem; }

.Footer_footer__3lHA_ > h5 {
  font-size: 1.125rem;
  margin-bottom: 0.625rem; }

.Footer_footer__3lHA_ > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.Footer_footer__3lHA_ > div > svg {
  margin-right: 0.4375rem;
  -webkit-flex-shrink: 0;
          flex-shrink: 0; }

.Footer_footer__3lHA_ > div > p {
  font-size: 0.875rem;
  font-weight: 400; }

.Footer_footer__3lHA_ > div > a {
  font-size: 0.875rem;
  font-weight: 400; }

.Footer_footer__3lHA_ > div + div {
  margin-top: 1rem; }

.Footer_footer__3lHA_ > p {
  font-size: 0.875rem;
  margin-top: 2.5rem; }

body {
  margin: 0;
  padding: 0; }

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.SampleInfo_page__2dudD {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 0 auto;
  background: #eee; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ {
  background: #fff;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ > :nth-child(2n) {
  margin: 4cm 0; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ > :first-child {
  margin: 0; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 {
  height: 108.5mm;
  border: 1px solid black;
  width: 100%;
  padding: 1cm;
  position: relative; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > img {
  width: 90%;
  opacity: 0.2;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_origin-section__AP9S5 {
  height: 2cm;
  margin-bottom: 0.5cm; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_origin-section__AP9S5 > .SampleInfo_id__39qYu {
  font-size: 30px;
  margin-bottom: 20px; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_origin-section__AP9S5 > .SampleInfo_id__39qYu > span {
  margin-left: 10px;
  font-size: 17px;
  font-weight: 500; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_origin-section__AP9S5 > .SampleInfo_flag-origin-region__2nF3q {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_origin-section__AP9S5 > .SampleInfo_flag-origin-region__2nF3q > .SampleInfo_origin-region__DV9UX {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 700; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_info-box__25g5w {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify;
  height: 63.5mm; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_info-box__25g5w > section > .SampleInfo_info-wrapper__10HOJ {
  width: 100%; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_info-box__25g5w > section > .SampleInfo_info-wrapper__10HOJ > .SampleInfo_info__38bTp {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify;
  margin-bottom: 10px; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_info-box__25g5w > section > .SampleInfo_info-wrapper__10HOJ > .SampleInfo_info__38bTp > p {
  font-size: 20px; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_info-box__25g5w > section > .SampleInfo_info-wrapper__10HOJ > .SampleInfo_info__38bTp > h5 {
  text-align: right;
  font-size: 20px; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_left__3t8Zy {
  width: 100%;
  height: 100%;
  padding-right: 20px;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify;
  -webkit-flex: 0 0 45%;
  /* Safari 6.1+ */
  /* IE 10 */
  flex: 0 0 45%; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_left__3t8Zy > .SampleInfo_img-wrapper__36awP {
  position: relative;
  width: 90%;
  height: 200px;
  margin: 0 auto; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_left__3t8Zy > .SampleInfo_img-wrapper__36awP > .SampleInfo_img__2o5Tf {
  width: 100%;
  height: 100%; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_left__3t8Zy > .SampleInfo_img-wrapper__36awP > .SampleInfo_flag__3lJe8 {
  padding: 5px;
  position: absolute;
  top: 0;
  left: 0; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_left__3t8Zy > .SampleInfo_id__39qYu {
  width: 100%;
  text-align: center; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_right__3Z74k {
  width: 100%;
  height: 100%;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_right__3Z74k > .SampleInfo_info-box__25g5w {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify;
  -webkit-flex: 1 0 auto;
  /* Safari 6.1+ */
  /* IE 10 */
  flex: 1 0 auto; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_right__3Z74k > .SampleInfo_info-box__25g5w > .SampleInfo_info-wrapper__10HOJ {
  width: 100%; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_right__3Z74k > .SampleInfo_info-box__25g5w > .SampleInfo_info-wrapper__10HOJ > .SampleInfo_info__38bTp {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 10px; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_right__3Z74k > .SampleInfo_info-box__25g5w > .SampleInfo_info-wrapper__10HOJ > .SampleInfo_info__38bTp > p {
  font-size: 17px; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_right__3Z74k > .SampleInfo_info-box__25g5w > .SampleInfo_info-wrapper__10HOJ > .SampleInfo_info__38bTp > h5 {
  font-size: 17px; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_right__3Z74k > .SampleInfo_info-box__25g5w > .SampleInfo_info-wrapper2__QFxMy {
  width: 100%; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_right__3Z74k > .SampleInfo_info-box__25g5w > .SampleInfo_info-wrapper2__QFxMy > .SampleInfo_info__38bTp {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify;
  margin-bottom: 10px; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_right__3Z74k > .SampleInfo_info-box__25g5w > .SampleInfo_info-wrapper2__QFxMy > .SampleInfo_info__38bTp > p {
  -webkit-flex: 1 0 120px;
  /* Safari 6.1+ */
  /* IE 10 */
  flex: 1 0 120px;
  font-size: 17px; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_right__3Z74k > .SampleInfo_info-box__25g5w > .SampleInfo_info-wrapper2__QFxMy > .SampleInfo_info__38bTp > h5 {
  text-align: right;
  font-size: 17px; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_right__3Z74k > .SampleInfo_info-box__25g5w > .SampleInfo_impt__33Ge_ {
  width: 100%;
  border-left: 5px solid #0072ce;
  padding-left: 10px; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_right__3Z74k > .SampleInfo_info-box__25g5w > .SampleInfo_impt__33Ge_ > .SampleInfo_info__38bTp {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify; }

.SampleInfo_page__2dudD .SampleInfo_subpage__3qil_ .SampleInfo_contents__3BLr9 > .SampleInfo_right__3Z74k > .SampleInfo_info-box__25g5w > .SampleInfo_impt__33Ge_ > .SampleInfo_info__38bTp > h5 {
  font-size: 18px; }

@page {
  size: A4;
  margin: 0; }

@media print {
  html, body {
    width: 210mm;
    height: 297mm; }
  .SampleInfo_page__2dudD {
    margin: 0;
    border: medium none currentColor;
    border: initial;
    width: auto;
    width: initial;
    min-height: 0;
    min-height: initial;
    box-shadow: none;
    box-shadow: initial;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial;
    page-break-after: always; } }

