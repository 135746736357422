.bg {
  background: white;
  padding: 5rem; }
  @media (max-width: 779px) {
    .bg {
      padding: 5rem 0.625rem; } }

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  max-width: 50rem; }
  .container .item {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: row;
    -ms-flex-direction: row;
    align-items: center;
    -ms-flex-align: center;
    margin-bottom: 2.5rem; }
    .container .item > span > img {
      width: 10.625rem;
      margin-right: 2.8125rem;
      border-radius: 6.25rem; }
    .container .item .text-box {
      flex: 1 1 -moz-fit-content;
      -ms-flex: 1; }
      .container .item .text-box > h4 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.625rem; }
      .container .item .text-box > p {
        font-size: 0.875rem;
        line-height: 1.6; }
    @media (max-width: 779px) {
      .container .item {
        display: -webkit-box;
        /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;
        /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;
        /* TWEENER - IE 10 */
        display: -webkit-flex;
        /* NEW - Chrome */
        display: flex;
        /* NEW, Spec - Opera 12.1, Firefox 20+ */
        flex-direction: column;
        -ms-flex-direction: column; }
        .container .item > span > img {
          margin-right: 0; }
        .container .item .text-box > h4 {
          text-align: center;
          margin-top: 1.25rem; } }
