.bg {
  background: white; }
  .bg .select-section {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    max-width: 56.25rem;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: row;
    -ms-flex-direction: row;
    justify-content: space-between;
    -ms-flex-pack: justify;
    padding: 5rem 0; }
    .bg .select-section > .box {
      -webkit-flex: 0 0 48%;
      /* Safari 6.1+ */
      -ms-flex: 0 0 48%;
      /* IE 10 */
      flex: 0 0 48%;
      display: -webkit-box;
      /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box;
      /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox;
      /* TWEENER - IE 10 */
      display: -webkit-flex;
      /* NEW - Chrome */
      display: flex;
      /* NEW, Spec - Opera 12.1, Firefox 20+ */
      flex-direction: column;
      -ms-flex-direction: column;
      justify-content: space-between;
      -ms-flex-pack: justify;
      height: 20rem; }
      .bg .select-section > .box .text-wrapper > h4 {
        font-size: 1.25rem;
        margin-bottom: 0.9375rem;
        color: black; }
      .bg .select-section > .box .text-wrapper > p {
        font-size: 1rem;
        margin-bottom: 0.9375rem; }
      .bg .select-section > .box > button {
        font-size: 1.25rem;
        width: 100%;
        height: 3.125rem; }
      .bg .select-section > .box > button.buyer {
        background: #008EFB;
        color: white; }
        .bg .select-section > .box > button.buyer:hover {
          background: #0071c8; }
      .bg .select-section > .box > button.seller {
        background: white;
        border: 1px solid black; }
        .bg .select-section > .box > button.seller:hover {
          border-color: #757575;
          color: #757575; }

@media (max-width: 767px) {
  .bg .select-section {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: column;
    -ms-flex-direction: column; }
    .bg .select-section > .box {
      height: unset;
      margin-bottom: 5rem; }
      .bg .select-section > .box .text-wrapper {
        margin-bottom: 1.25rem; }
        .bg .select-section > .box .text-wrapper > p {
          margin-bottom: 0.625rem;
          height: unset; } }
